import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fileUpload } from '../services/services.service';
import Snackbar from './Snackbar/Snackbar';

interface FileUploadProps {
  meetingId: string;
  token: string;
  onFileUploaded: () => void; // Función para notificar al padre que se ha subido un archivo
}

const FileUpload: React.FC<FileUploadProps> = (props) => {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [selectedFileName, setSelectedFileName] = useState<Array<string>>(['']);
  const [errorMessage, setErrorMessage] = useState<[string, string]>(['', '']);
  const [open, setOpen] = useState<boolean>(false); // Controla la visibilidad del Snackbar
  const [isError, setIsError] = useState<boolean>(false); // Controla la visibilidad del Snackbar

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setSelectedFiles(files);
      setSelectedFileName(
        Array.from(files).map((file) => file.name + "\r")); // Guarda el nombre del primer archivo seleccionado
    } else {
      setSelectedFiles(null);
      setSelectedFileName(['']);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!selectedFiles || selectedFiles.length === 0) {
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files', selectedFiles[i]);
    }

    try {
      await fileUpload(formData, props.meetingId, props.token);
      setOpen(true)
      setErrorMessage(["correcto", "userMessage"]);
      setIsError(false);
      setSelectedFiles(null);
      setSelectedFileName(['']);
      props.onFileUploaded();
    } catch (error) {
      console.error('Error uploading files:', error);
      setOpen(true)
      setErrorMessage(["errorCode", "userMessage"]);
      setIsError(true);

      // Manejar errores de carga de archivos aquí, si es necesario
    }



    
  };
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (open) {
      // Configuramos el temporizador para ocultar el Snackbar después de 5 segundos (5000ms)
      timer = setTimeout(() => {
        setOpen(false);
      }, 5000);
    }

    // Limpiamos el temporizador al desmontar el componente o si el estado cambia
    return () => clearTimeout(timer);
  }, [open]);

  return (
    <form onSubmit={handleSubmit} >
      <div className="selectorButton">
      <Snackbar message={errorMessage[0]} open={open} isError={isError}/>

        <label className="customFileUpload">
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ display: 'none'}}
          />
          {t('consultaDocumentacio.seleccionar_arxiu')}
        </label>
        <ul className="document-list">
        {selectedFileName && selectedFileName.map((doc) => (
          <li key={doc} className="document-item">
            <span className="document-link">
              ·{(doc)}
            </span>
          </li>
        ))}
        </ul>

      </div>
      <div className="submitButton">
        <button disabled={selectedFiles==null || selectedFiles.length<0} type="submit" className="selectFileButton">
          {t('consultaDocumentacio.pujar_arxiu')}
        </button>
      </div>

    </form>
  );
};

export default FileUpload;
