import React from 'react';
import './Snackbar.css'; // Importa los estilos
import { t } from 'i18next';

interface SnackbarProps {
  message: string;
  open: boolean;
  isError:boolean
}

const Snackbar: React.FC<SnackbarProps> = ({ message, open, isError }) => {
  return (
    <>
      {open && (
        <div className={`snackbar ${isError ? 'error' : 'success'}`}>
          <p>{t(message)}</p>
        </div>
      )}
      
    </>
  );
};

export default Snackbar;
