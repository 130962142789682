import React from 'react';
import './InfoCard.css';

const Card = () => {
  return (
    <div className="card">
      <h3>Explicació pel Ciutadá</h3>
      <p>Informació relativa al procés que serveix d'exemple.</p>
    </div>
  );
}

export default Card;