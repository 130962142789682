import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AplicacioCarrega from "./components/AplicacioCarrega";
import ConsultaDocumentacio from './components/ConsultaDocumentacio';
import AccesAplicacio from './components/AccesAplicacio';

import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Card from './utils/InfoCard/InfoCard';

function App() {

  return (
    <>
    <Header />
    <Card></Card>
    <Router>
      <Routes>
        <Route path='/:id' element= {<AplicacioCarrega></AplicacioCarrega>}></Route>
        <Route path='/ConsultaDocumentacio' element= {<ConsultaDocumentacio></ConsultaDocumentacio>}></Route>
        <Route path='/AccesAplicacio' element= {<AccesAplicacio></AccesAplicacio>}></Route>
      </Routes>
    </Router>
    <Footer/>
    </>
  )
}

export default App;